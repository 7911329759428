export const fr = {
  shared: {
    yes: 'Oui',
    no: 'Non',
  },
  statDialog: {
    title: 'Statistiques des mesures: {{date}}',
    minimum: 'Minimum',
    maximum: 'Maximum',
    average: 'Moyenne',
    measurementType: 'Capteur',
  },
  import: 'Importer',
  mapHeaderBanner: {
    sitesCount: '{{sitesCount}} sites',
    structuresCount: '{{structuresCount}} ouvrages équipés',
    alertsCount: '{{alertsCount}} alertes seuil activées',
    structureSelection: 'Sélectionnez un ouvrage',
    lastMeasurementSeen: 'Dernieres mesures',
    btnNoMeasurementSeen: 'Mesures',
  },
  navigationMenu: {
    home: 'Accueil',
    sites: 'Sites',
    companies: 'Sociétés',
    measures: 'Mesures',
    alarms: 'Alarmes',
    devices: 'Balises',
    structures: 'Ouvrages',
    measurers: 'Capteurs',
    users: 'Utilisateurs',
    alerts: 'Alertes',
    parcStatus: 'Etat du parc',
    sitesDetails: 'Détails du site',
    addSite: 'Ajouter un site',
    structuresDetails: "Détails de l'ouvrage",
    usersDetails: "Détails de l'utilisateur",
    companiesDetails: 'Détails de la société',
    addCompany: 'Ajout société',
    alarmTriggerDetails: "Détails de l'alarme",
    deviceDetails: 'Détails de la balise',
    measurersDetails: 'Détails du capteur',
    faq: 'FAQ',
    contact: 'Contact',
    accountDropDown: {
      accountManagement: 'Gestion des comptes',
      logout: 'Déconnexion',
      teamOwner: 'Team Owner',
    },
  },

  availableLanguages: {
    en: 'Anglais',
    fr: 'Français',
  },
  logging: {
    login: 'Solices Connection Starter',
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    loginBtn: 'Connexion',
  },
  notification: {
    error: 'Erreur',
    http_error: {
      err_fr_too_many_redirects:
        'Erreur serveur, contactez votre administrateur',
      err_bad_option_value: 'Erreur serveur, contactez votre administrateur',
      err_bad_option: 'Erreur serveur, contactez votre administrateur',
      err_network: 'Erreur de connexion, vérifiez votre connexion internet',
      err_deprecated: 'Erreur serveur, contactez votre administrateur',
      err_bad_response: 'Erreur serveur, contactez votre administrateur',
      err_bad_request: 'Erreur serveur, contactez votre administrateur',
      err_not_support: 'Erreur serveur, contactez votre administrateur',
      err_invalid_url: 'Erreur serveur, contactez votre administrateur',
      err_canceled: 'Requête annulée par votre navigateur',
      econnaborted: 'Erreur serveur, contactez votre administrateur',
      etimedout:
        'Demande annulée : durée trop longue, vérifiez votre connexion internet',
      unknown_error: 'Erreur inconnue',
      unauthorized_error: "Cette action nécessite d'être connecté",
      forbidden_error:
        "Vous n'avez pas les droits suffisants pour accéder à la ressource",
      notfound_error: 'Ressource non trouvée',
      conflict_error: 'Cet élément existe déjà',
    },
    success: 'Succès',
    create: {
      error: 'Erreur de création: ',
      success: 'Création réussie',
    },
    update: {
      error: 'Erreur de mise à jour: ',
      success: 'Mise à jour réussie',
    },
    delete: {
      error: 'Erreur de suppression: ',
      success: 'Suppression réussie',
    },
  },
  authentication: {
    checkAuthentication: 'En attente de connexion...',
  },
  datePlaceholder: 'jj/mm/aaaa',
  navigation: {
    goBack: 'Retour',
    validate: 'Valider',
    delete: 'Supprimer',
  },
  device: {
    device: 'Balise',
    deviceIdentifier: 'Identifiant',
    deviceName: 'Nom',
    serialNumber: 'Numéro de série',
    iotNetwork: 'Réseau IOT',
    lastMeasurementDate: 'Dernier relevé',
    lastMeasurementDurationDetails: 'Durée',
    location: 'Lieu',
    nbrAlarms: "Nombres d'alarmes",
    add: 'Importer une balise',
    goToChildren: 'Voir les capteurs associés à cette balise',
    synchronizeBtn: 'Synchroniser',
    noOptionsText: 'Aucune balise trouvée',
    synchronizeDialogContent:
      'Cette action peut prendre plusieurs secondes. Etes-vous sur ?',
    explanation1: 'Etape 1 : recherchez une balise par son numéro IMEI',
    explanation2:
      "Après import de la balise, vous serez redirigé vers l'import de capteurs",
    explanation3: 'Etape 3 : Finalisation de la configuration',
    explanation4:
      "En appuyant sur le bouton Synchroniser, vous importez l'historique des données et enclenchez la mise à jour automatique des données",
  },
  GPRS: {
    iotAddress: 'Adresse IOT GPRS',
  },
  location: {
    company: 'Société',
    site: 'Site',
    structure: 'Ouvrage',
    locationName: 'Nom',
    locationType: 'Type',
    parentLocation: 'Lieu parent',
    society: 'Lieu parent',
    geoLocation: 'Coordonnées géographiques',
    yLat: 'Latitude',
    xLon: 'Longitude',
    nbrAlarms: "Nombre d'alarmes",
    search: 'Indiquez une adresse',
    address: {
      street: 'Rue',
      postalCode: 'Code Postal',
      countryCode: 'Pays',
      city: 'Ville',
      googleFormattedAddress: 'Addresse complète',
    },
  },
  alarm: {
    measurerId: 'Capteur',
    deviceIdentifier: 'Balise',
    measurementType: 'Type de donnée',
    eventDate: 'Date',
    alarmState: 'Etat',
    add: 'Ajouter une alarme',
    activate: 'Activer une alarme',
    notifiedEmails: 'Emails à notifier',
    notifiedPhoneNumbers: 'Numéros de téléphone à notifier',
    noAlarm: 'Aucune alarme',
    deviceName: 'Balise',
    measurementValue: 'Valeur',
    thresholdValue: 'Seuil',
  },
  alarmTrigger: {
    measurer: 'Capteur',
    device: 'Balise',
    thresholdValue: {
      fieldValue: "Seuil d'alerte",
    },
    hysteresisValue: {
      fieldValue: 'Hystérésis',
    },
    boundDirection: 'Tendance',
    severityLevel: "Niveau d'alerte",
    synchronize: 'Synchroniser',
  },
  measurer: {
    measurerCategory: 'Catégorie',
    import: 'Importer un capteur',
    measurementType: 'Donnée mesurée',
    alarmState: 'État alarme',
    device: 'Balise',
    label: 'Légende',
    lastAlarmDate: 'Dernière alarme',
    measurementDataType: 'Type de données',
    measurementUnit: 'Unité de mesure',
    saveAndConfigureNext: 'Sauvegarder et configurer le capteur suivant',
    saveAndAddNewMeasurer: "Sauvegarder et ajouter d'autres capteurs",
    saveAndFinishConfiguration:
      'Sauvegarder et terminer la configuration de la balise',
    explanation1: 'Etape 2 : Import de pack de capteurs',
    explanation2:
      'Vous pouvez sélectionner ici des groupes de capteurs, vous serez redirigés vers la configuration de ces capteurs.',
    specificParameters: {
      max_analog_ma: 'Max analogique (ma)',
      min_numeric_meter: 'Min numérique (m)',
      max_numeric_meter: 'Max numérique (m)',
      min_numeric_degree: 'Min numérique (°C)',
      max_numeric_degree: 'Max numérique (°C)',
      min_analog_ma: 'Min analogique (ma)',
      reference_void_level: 'Niveau de vide de référence',
      reference_water_level: "Niveau d'eau de référence",
      battery_full_volt: 'Tension de la batterie chargée',
      valeur_pleine_echelle: 'Valeur pleine échelle',
      mesure_manuelle: 'Mesure manuelle',
      reference_ngf_level_meter: 'Niveau de référence ngf (m)',
      reference_water_depth_meter: "Profondeur mesurée (m)",
      reference_water_height_meter: "Hauteur d'eau de mesurée sur site (m)",
      calibration_water_height_meter: "Mesure de la hauteur d'eau (m)",
      calibration_date: 'Date de mise en service',
      longueur_surverse_meter: 'Longueur de la surverse (m)',
      largeur_canal_meter: 'Largeur du canal (m)',
      hauteur_pelle_meter: 'Hauteur de pelle (m)',
      coefficient_debit: 'Coefficient de décharge',
      angle_sommet_deversoir_degree: 'Angle de l\'ouverture en V',
    },
    package: 'Module',
    packageName: {
      starter_pack: 'Module de base',
      temperature_analog_to_numeric: 'Convertisseur température analogique numérique',
      void_level_from_water_level: "Niveau de vide à partir du niveau d'eau",
      water_level_analog: "Niveau d'eau analogique",
      void_level: 'Niveau de vide',
      water_level_from_void_level: "Niveau d'eau à partir du niveau de vide",
      temperature: 'Température',
      water_height_relative: "Hauteur d'eau relative",
      water_depth_relative: "Profondeur d'eau relative",
      water_depth_ngf: "Profondeur d'eau ngf",
      water_height_nbf: "Hauteur d'eau ngf",
      debit_deversoir_noye: 'Débit déversoir à ouverture rectangulaire',
      debit_deversoir_denoye: 'Débit déversoir à ouverture triangulaire',
      debit_orifice_denoye: 'Débit orifice dénoyé',
    },
    add: 'Importer un capteur',
    goToChildren: 'Voir les alarmes de ce capteur',
  },
  validator: {
    empty: '{{param}} ne peut pas être vide',
    enum: 'Mauvaise donnée',
    selectLocationLink: 'Vous devez séléctionner un lieu parent',
    selectDeviceLink: 'Vous devez séléctionner une balise',
    selectMeasurer: 'Vous devez séléctionner un capteur',
    website: 'Doit commencer par http:// ou https://',
  },
  company: {
    identificationNumber: 'Siret',
    companyCode: 'Code',
    legalForm: 'Forme légale',
    phoneNumber: 'Téléphone',
    email: 'Email',
    website: 'Site web',
    create: {
      error: "Erreur de création de l'entreprise: ",
      success: 'Vous avez créé une entreprise',
    },
    add: 'Ajouter une société',
  },
  site: {
    code: 'Code',
    add: 'Ajouter un site',
    goToChildren: 'Voir les ouvrages rattachés à ce site',
  },
  structure: {
    structureCode: 'Code',
    comment: 'Commentaire',
    structureType: "Type d'ouvrage",
    add: 'Ajouter un ouvrage',
    goToChildren: 'Voir les balises rattachés à cet ouvrage',
  },
  locationType: {
    structure: 'Ouvrage',
    company: 'Société',
    site: 'Site',
  },
  alarmState: {
    ok_no_alarm: "Pas d'alarme",
    upper_bound_minor: 'Supérieure à, mineure',
    upper_bound_major: 'Supérieure à, majeure',
    lower_bound_minor: 'Inférieure à, mineure',
    lower_bound_major: 'Inférieure à, majeure',
  },
  alarmBoundDirection: {
    upper_bound: 'Supérieure à',
    lower_bound: 'Inférieure à',
  },
  alarmSeverityLevel: {
    minor: 'Mineur',
    major: 'Majeur',
  },
  measurementType: {
    pressure: 'Pression',
    pressure_analog: 'Pression analogique',
    water_level: "Niveau d'eau",
    temperature: 'Température',
    humidity: 'Humidité',
    battery: 'Batterie',
    battery_percent: 'Pourcentage batterie',
    network_coordinates: 'Coordonnées du réseau',
    keep_alive: 'Keep alive',
    co2: 'CO2',
    cov: 'COV',
    external_temperature_channel_1: 'Température exterieure canal 1',
    external_temperature_channel_2: 'Température exterieure canal 2',
    void_level: 'Niveau de vide',
    water_level_analog: "Niveau d'eau analogique",
    water_depth_raw: "Profondeur d'eau brute",
    temperature_analog: 'Température analogique',
    water_height: "Hauteur d'eau",
    water_depth_relative: 'Profondeur',
    water_depth_ngf: 'Cote ngf',
    water_height_relative: "Hauteur d'eau relative",
    water_height_ngf: "Cote ngf",
    water_flow: "Débit d'eau",
  },
  measurerCategory: {
    sensor: 'Capteur',
    calculator: 'Calculateur',
    sounder: 'Etat',
  },
  measurementUnit: {
    degree: 'Degré',
    hectopascal: 'Hectopascal',
    percent: 'Pourcentage',
    volt: 'Volt',
    lux: 'Lux',
    millimeter: 'Millimètre',
    meter: 'Mètre',
    latitude_longitude: 'Latitude longitude',
    ppm: 'Ppm',
    ampere: 'Ampère',
    second: 'Secondes',
  },
  structureType: {
    surface_water: 'Eaux de surface',
    groundwater: 'Eaux souterraines',
    conduct: 'Conduite',
  },
  user: {
    login: 'Login',
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Email',
    secondEmail: 'Email 2',
    mobilePhoneNumber: 'Téléphone portable',
    fixedPhoneNumber: 'Téléphone fixe',
    code: 'Code',
    company: 'Société',
    createdDate: 'Date de création',
    lastModifiedDate: 'Date de dernière modification',
    createdBy: 'Créé par',
    lastModifiedBy: 'Dernière modification par',
    activated: 'Actif',
    langKey: 'Langue',
    activatedState: {
      true: 'Oui',
      false: 'Non',
    },
    authorities: 'Rôle',
    add: 'Ajouter un utilisateur',
    user: 'Utilisateur',
    admin: 'Administrateur',
    manager: 'Manager',
    location: 'Site/structure',
    addUserLocation: 'Ajouter',
    permissions: 'Permissions',
    permission: {
      create: 'Création',
      read: 'Lecture',
      update: 'Modification',
      delete: 'Suppression',
      cancel: 'Annuler',
      all: 'Tous',
    },
  },
  dataGrid: {
    action: 'Action',
  },
  modal: {
    deleteTitle: 'Confirmer la suppression',
    confirmDelete: 'Êtes-vous sûr de bien vouloir supprimer cet élément ?',
  },
  measurement: {
    filterPeriod: {
      last7days: '7j',
      lastWeek: '1s',
      lastMonth: '1m',
      lastSemester: '6m',
      lastYear: '1an',
      aec: 'aaj',
      max: 'max',
    },
    dateRange: {
      beginDate: 'Date de début',
      endDate: 'Date de fin',
    },
    deviceIdentifier: 'Balises',
    deviceName: 'Balises',
    measurementType: 'Donnée mesurée',
    period: 'Période',
    measurementDate: 'Date',
    measurerValue: 'Valeur',
    averageValue: 'Valeur',
    measurer: 'Capteur',
    chooseMeasurer: 'Choisir un capteur',
    validate: 'Validate',
    seeGraph: 'Afficher le graphique',
    seeChronicles: 'Afficher les chroniques',
    compareMeasures: 'Comparer les périodes',
    statistics: 'Statistiques',
    gotoMap: 'Carte',
  },
  googleMap: {
    satellite: 'Vue satellite',
    infoWindow: {
      goTo: 'Voir site',
    },
  },
  pwa: {
    calibration_date: 'Etalonnage',
    updateFrequency: "Fréquence d'envoi (heures)",
  },
  image: {
    fileName: 'Nom',
    mediaType: 'Type',
    upload: 'Ajouter',
    download: 'Télécharger',
    delete: 'Supprimer',
  },
  time: {
    days_one: '{{count}} jour',
    days_other: '{{count}} jours',
    hours_one: '{{count}} heure',
    hours_other: '{{count}} heures',
    minutes_one: '{{count}} minute',
    minutes_other: '{{count}} minutes',
    seconds_one: '{{count}} seconde',
    seconds_other: '{{count}} secondes',
  },
};
